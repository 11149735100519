<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <div class="header_content">
        <div class="name">沃赛科技</div>
        <div style="margin: 16px 0 20px 0">专注城市智慧化建设</div>
        <div class="more" @click="go(11)">了解更多</div>
      </div>
    </div>
    <div class="title">核心业务</div>
    <div class="modular">
      <div class="img img1" @click="go(1)">
        <div class="img_title">智慧小区</div>
      </div>
      <div class="img-top">
        <div class="imgbox img3 img" @click="go(3)">
          <div class="img_title">智慧楼宇</div>
        </div>
        <div class="imgbox img img4" @click="go(4)">
          <div class="img_title">智慧园区</div>
        </div>
      </div>
      <div class="img-top">
        <div class="imgbox img img99" @click="go(10)">
          <div class="img_title">智慧交通</div>
        </div>
        <div class="imgbox img img6" @click="go(6)">
          <div class="img_title">数字乡村</div>
        </div>
      </div>
      <div class="img-top">
        <div class="imgbox img img5" @click="go(5)">
          <div class="img_title">智慧警务</div>
        </div>
        <div class="imgbox img2 img" @click="go(2)">
          <div class="img_title">智慧城市</div>
        </div>
      </div>
      <div class="img-top">
        <!-- <div class="imgbox img img8" @click="go(8)">
          <div class="img_title">基层治理</div>
        </div> -->
        <div class="imgbox img img7" @click="go(7)">
          <div class="img_title">政务大数据</div>
        </div>
        <div class="imgbox img img9" @click="go(19)">
          <div class="img_title">智慧水务</div>
        </div>
      </div>
    </div>
    <h1>合作伙伴</h1>
    <div class="img10"></div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {};
  },

  methods: {
    go(item) {
      if (item == 1) {
        this.$router.push("m_residentialQuarters");
      }

      if (item == 2) {
        this.$router.push("m_city");
      }

      if (item == 3) {
        this.$router.push("m_building");
      }

      if (item == 4) {
        this.$router.push("m_park");
      }

      if (item == 5) {
        this.$router.push("m_fbi");
      }

      if (item == 6) {
        this.$router.push("m_rural");
      }

      if (item == 7) {
        this.$router.push("m_government");
      }

      if (item == 8) {
        this.$router.push("m_basic");
      }

      if (item == 19) {
        this.$router.push("m_waterAffairs");
      }
      if (item == 10) {
        this.$router.push("m_traffic");
      }
      if (item == 11) {
        this.$router.push("m_home");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  box-sizing: border-box;
  // padding: 0 10px;
  margin-top: -27px;
  .header {
    position: relative;
    width: 100%;
    height: 242px;
    background-color: #333;
    background: url("../../assets/assets/homeImg/df.png") no-repeat;
    background-size: 100% 100%;
    color: #fff;
    .header_content {
      width: 100%;
      position: absolute;
      // background-color: #ccc;
      left: 0;
      bottom: 32px;
      text-align: center;
      font-size: 20px;
      .more {
        font-size: 14px;
        border: 1px solid #fff;
        width: auto;
        display: inline-block;
        padding: 8px 32px;
        border-radius: 32px;
      }
    }
  }
  .title {
    width: 100%;
    height: 40px;
    background-color: #3651d8;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .modular {
    padding: 10px;
    background: #f2f3f8;
    > div {
      display: flex;
    }
    .img {
      position: relative;
      width: 100%;
      height: 232px;
      .img_title {
        position: absolute;
        bottom: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .img-top {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .imgbox {
      width: calc(50% - 5px);
    }
    .img_title {
      position: absolute;
      bottom: 0;
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .img1 {
      background: url("../../assets/assets/homeImg/img1.png") no-repeat;
      background-size: 100% 100%;
    }
    .img2 {
      background: url("../../assets/assets/homeImg/img2.png") no-repeat;
      background-size: 100% 100%;
    }
    .img3 {
      background: url("../../assets/assets/homeImg/img3.png") no-repeat;
      background-size: 100% 100%;
    }
    .img4 {
      background: url("../../assets/assets/homeImg/img4.png") no-repeat;
      background-size: 100% 100%;
    }
    .img5 {
      background: url("../../assets/assets/homeImg/img5.png") no-repeat;
      background-size: 100% 100%;
    }
    .img6 {
      background: url("../../assets/assets/homeImg/img6.png") no-repeat;
      background-size: 100% 100%;
    }
    .img7 {
      background: url("../../assets/assets/homeImg/img7.png") no-repeat;
      background-size: 100% 100%;
    }
    .img8 {
      background: url("../../assets/assets/homeImg/img8.png") no-repeat;
      background-size: 100% 100%;
    }
    .img9 {
      background: url("../../assets/assets/mbg1.png") no-repeat;
      background-size: 100% 100%;
    }
    .img99 {
      background: url("../../assets/assets/homeImg/img99.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  > h1 {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-bottom: 10px;
  }
  .img10 {
    margin: 0 10px;
    height: 148px;
    background: url("../../assets/assets/homeImg/img10.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
